/* Copyright (C) 2016 NooBaa */

import template from './splash-screen.html';

class SplashScreenViewModel  {
}

export default {
    viewModel: SplashScreenViewModel,
    template: template
};
