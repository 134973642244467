/* Copyright (C) 2016 NooBaa */

import template from './overview-panel.html';

class OverviewPanelViewModel {
    constructor() {
    }
}

export default {
    viewModel: OverviewPanelViewModel,
    template: template
};
