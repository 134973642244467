module.exports={
    "patchFile": "/fe/config.patch.json",
    "inputThrottle": 300,
    "paginationPageSize": {
        "default": 25,
        "options": [10, 25, 50]
    },
    "infinitScrollPageSize": 25,
    "timeShortFormat": "DD MMM YYYY HH:mm:ss",
    "timeLongFormat": "DD MMM YYYY HH:mm:ss ([GMT]Z)",
    "sslCertificateSuffix": ".zip",
    "serverRestartWaitInterval": 20000,
    "readAuthRetryCount": 2,
    "readAuthRetryDelay": 1000,
    "sessionTokenKey": "sessionToken",
    "previewContentKey": "previewContent",
    "redirectOverlayDuration": 4000,
    "timeTickInterval": 1000,
    "autoRefreshInterval": 30000,
    "maxTiersAllowed": 2,
    "nodeTest": {
        "targetCount": 20,
        "testSettings": {
            "connectivity": {
                "stepCount": 1,
                "requestLength": 0,
                "responseLength": 0,
                "count": 1,
                "concur": 1
            },
            "bandwidth": {
                "stepCount": 8,
                "requestLength": 524288,
                "responseLength": 524288,
                "count": 64,
                "concur": 16
            }
        }
    },
    "notifications": {
        "minTimeOnScreen": 3200,
        "charTimeContribution": 50
    },
    "supportedBrowsers": [
        "chrome"
    ],
    "preferdBrowsers": [
        "chrome"
    ],
    "support":  {
        "email": "support@noobaa.com",
        "createSystemSupportSubject": "Need help creating a system",
        "missingOVAMailSubject": "Please help me find my original OVA",
        "managementConsoleSubject": "My NooBaa management console has crushed",
        "cannotResetAccountPasswordSubject": "Cannot reset account password"
    },
    "releaseNotes": {
        "baseUrl": "https://storage.googleapis.com/noobaa-fe-assets/release-notes",
        "suffix": "txt"
    },
    "logo": "logo-up",
    "themes": {
        "dark": "theme-up-dark",
        "light": "theme-up-light"
    },
    "defaultTheme": "dark"
}
